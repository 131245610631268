import { useCallback } from 'react';
import { useApiCall } from '@jarvis/react-portal-addons';
import { DeviceApiClientExt } from '../ext/web-stratus-client/device.api.ts';
import {
  LevelStates,
  SupplyColorSupported,
  SupplyDelivery,
  SupplyNames,
  SupplyStates,
  SupplyTypes,
  SupplyTypesCustom,
} from '../components/DeviceSupplies/constants';

function useSuppliesApiCall({
  authProvider,
  deviceId,
  init,
  levels,
  modelNumber,
  stack,
  supplyType,
  featureFlags,
}) {
  const fetchStates = useCallback(async cloudId => {
    const deviceClient = new DeviceApiClientExt(stack, authProvider);
    // 1 - get supply states using deviceId (devices api/devices/v1/{deviceId}/suppliesStatus)
    const deviceSuppliesStatus = await deviceClient.getDeviceSuppliesStatus(cloudId);
    return deviceSuppliesStatus?.data?.state?.reported?.cdmData?.supplyStates;
  }, [authProvider, stack]);

  const fetchModelNo = useCallback(async cloudId => {
    const deviceClient = new DeviceApiClientExt(stack, authProvider);
    // 2 - get device data (deviceIdentity.modelNumber) using deviceId (devices api/devices/v1/{deviceId})
    const device = await deviceClient.getDevice(cloudId);
    return device?.data?.deviceDescription?.deviceIdentity?.modelNumber;
  }, [authProvider, stack]);

  const fetchModelDetails = useCallback(async modelNo => {
    const deviceClient = new DeviceApiClientExt(stack, authProvider);
    // 3 - get supply type for that modelNumber (pls api/devices/v1/models/{modelNumber})
    const modelDetails = await deviceClient.getModelDetails(modelNo);
    return modelDetails?.data;
  }, [authProvider, stack]);

  const createLevel = useCallback(({
    colorCode: supplyName, // PLS + SupplyStatus
    pct10LevelRemaining: level = undefined, // from SupplyStatus
    levelState = undefined,
    isRefill = false,
    isVaReman = false,
    supplyState = undefined,
    slot: order = 0, // from PLS
  }) => {
    let createdLevel = {
      supplyName,
      level,
      levelState,
      supplyState,
      order,
    };
    if (supplyState === SupplyStates.error) {
      // feature flag to show the received level when state is error | zero is default behavior
      createdLevel = { ...createdLevel, level: featureFlags?.deviceSupplyUseLevelOnError ? level || 0 : 0 };
    } else if (isRefill === 'true' && isVaReman !== 'true') {
      createdLevel = { ...createdLevel, level: 0, supplyState: SupplyStates.warning };
    } else if (supplyState === SupplyStates.warning) {
      createdLevel = { ...createdLevel, level: level || 0 };
    } else if (level === 0 || levelState === LevelStates.depleted) {
      createdLevel = { ...createdLevel, supplyState: SupplyStates.error };
    } else if (levelState === LevelStates.veryLow || levelState === LevelStates.veryVeryLow) {
      createdLevel = { ...createdLevel, supplyState: SupplyStates.warning };
    } else if (level === undefined && levelState === undefined && supplyState === undefined) {
      // SDASH-9230: if no level, state or supplyState, show the correct "non-hp remanufactured" state
      createdLevel = { ...createdLevel, level: 0, supplyState: SupplyStates.ok };
    }
    return createdLevel;
  }, [featureFlags?.deviceSupplyUseLevelOnError]);

  const loadDeviceSupplies = useCallback(async () => {
    if (!authProvider && !stack) {
      if (!supplyType || !levels?.length > 0) {
        throw new Error('Rule: No levels or empty');
      }
      return { levels, supplyType };
    }

    const states = await fetchStates(deviceId);
    const supplyLevels = [];
    let resolvedSupplyType = null;
    if (states) {
      const modelNo = modelNumber || await fetchModelNo(deviceId);
      const modelDetails = await fetchModelDetails(modelNo);

      const modelSupplyType = modelDetails?.supplyType?.toLowerCase();
      const modelColorSupported = modelDetails?.colorSupported?.toLowerCase();
      const modelSupplyDelivery = modelDetails?.supplyDelivery?.toLowerCase();

      let supplyOrder = modelDetails?.supplies;
      if (modelSupplyType === SupplyTypes.toner) {
        if (modelColorSupported === SupplyColorSupported.MONO) {
          resolvedSupplyType = SupplyTypesCustom.MONO;
          // toner mono may come without supply order definition
          supplyOrder = modelDetails?.supplies || [{ colorCode: SupplyNames.K, slot: 0 }];
        } else {
          resolvedSupplyType = SupplyTypesCustom.TONER;
          // toner color may come without supply order definition
          supplyOrder = modelDetails?.supplies || [
            { colorCode: SupplyNames.C, slot: 1 },
            { colorCode: SupplyNames.M, slot: 2 },
            { colorCode: SupplyNames.Y, slot: 3 },
            { colorCode: SupplyNames.K, slot: 4 },
          ];
        }
      } else if (modelSupplyDelivery === SupplyDelivery.tank) {
        resolvedSupplyType = SupplyTypesCustom.TANK;
      } else if (modelDetails?.supplies?.some(item => item.colorCode === SupplyNames.CMY)) {
        resolvedSupplyType = SupplyTypesCustom.TRICOLOR;
      } else {
        resolvedSupplyType = SupplyTypesCustom.INK;
      }
      // collect the supply levels with slot order incorporated
      supplyOrder?.forEach(order => {
        const state = states[order.colorCode];
        supplyLevels.push(createLevel({ ...state, ...order }));
      });
    }

    if (supplyLevels.length === 0) {
      throw new Error('Rule: No levels or empty');
    }

    return {
      levels: supplyLevels,
      supplyType: resolvedSupplyType,
    };
  }, [
    authProvider, createLevel, deviceId, fetchModelDetails,
    fetchModelNo, fetchStates, levels, modelNumber, stack, supplyType,
  ]);

  return useApiCall({
    fetch: loadDeviceSupplies,
    init,
    initialData: { supplyType, levels },
  });
}

export default useSuppliesApiCall;
